export const environment = {
  production: false,
  name: 'dev',
  api_key: 'ULClyRg4Xk5xUUWWg9KF26RIK0AOtydnar8QsJ9z',
  api_base_url: 'https://api-dev.travelappeal.com',
  legacy_base_url: 'https://apibeta-dev.travelappeal.com',
  api_version: '/v4',
  default_redirect: 'https://index-dev.travelappeal.com',
  social_login: 'https://social-dev.travelappeal.com/auth/',
  website_url: 'https://www.travelappeal.com'
};
