import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader/loader.component';
import {ErrorBoxComponent} from './error-box/error-box.component';
import { TaLogoComponent } from './ta-logo/ta-logo.component';
import { SuccessBoxComponent } from './success-box/success-box.component';

@NgModule({
  declarations: [
    LoaderComponent,
    ErrorBoxComponent,
    SuccessBoxComponent,
    TaLogoComponent
  ],
  exports: [
    LoaderComponent,
    ErrorBoxComponent,
    SuccessBoxComponent,
    TaLogoComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LibModule {
}
