import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANG} from './utils/translation-utils';
import {HubspotUtils} from './utils/hubspot-utils';

@Component({
  selector: 'taa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(translateService: TranslateService) {
    translateService.setDefaultLang(DEFAULT_LANG);

    const browserLanguage = translateService.getBrowserLang();
    translateService.use(browserLanguage);
  }

  ngOnInit(): void {
    HubspotUtils.loadScript();
  }

}
