import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) {

  }

  private static getBaseUrl(): string {
    return environment.api_base_url;
  }

  get<T>(path: string, httpConfig?: object): Observable<T> {
    return this.httpClient.get<T>(HttpService.getBaseUrl() + path, httpConfig);
  }

  post<T>(path: string, body: any = null, httpConfig?: object): Observable<T> {
    return this.httpClient.post<T>(HttpService.getBaseUrl() + path, body, httpConfig);
  }
}
