import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'taa-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
