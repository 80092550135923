import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'taa-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {

  @Input() width = '3rem';
  @Input() height = '3rem';

  constructor() {
  }

  ngOnInit() {
  }

}
