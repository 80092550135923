import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../utils/translation-utils';

@Component({
  selector: 'taa-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorBoxComponent implements OnInit {

  @Input() errorLabel = 'errors.generic.unknown';

  public errorText: string;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    if (!this.errorLabel) {
      return;
    }

    const errorLabel = TranslationUtils.getError(this.errorLabel);
    this.translateService.get(errorLabel).subscribe((translated: string) => {
      this.errorText = translated;
    });
  }

}
