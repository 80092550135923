import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {JWT_COOKIE_DOMAIN, tokenGetter} from '../../utils/jwt-utils';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import cookies from 'js-cookie';

const JWT_HEADER_NAME = `x-login-${environment.name}-jwt`;
const LEGACY_COOKIE_NAME = `TravelAppeal_${environment.name}`;

@Injectable({
  providedIn: 'root'
})
export class LegacyService {

  public static extractCookie(legacyResponse): Promise<void> {
    return new Promise((resolve) => {
      cookies.set(LEGACY_COOKIE_NAME, legacyResponse[LEGACY_COOKIE_NAME], {
        path: '',
        domain: JWT_COOKIE_DOMAIN,
        expires: 365
      });
      resolve();
    });
  }

  public static removeCookie(): Promise<void> {
    return new Promise((resolve) => {
      cookies.remove(LEGACY_COOKIE_NAME, {
        path: '',
        domain: JWT_COOKIE_DOMAIN
      });

      resolve();
    });
  };

  constructor(private httpClient: HttpClient) {
  }

  login(): Observable<{ [p: string]: string }> {
    const loginHeaders = {
      'content-type': 'application/json',
      accept: 'application/json'
    };
    loginHeaders[JWT_HEADER_NAME] = tokenGetter();
    return this.httpClient.post<{ [p: string]: string }>(`${environment.legacy_base_url}/login-jwt`, null, {
      headers: loginHeaders
    });
  }
}
