import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';

export const DEFAULT_LANG = 'en';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class TranslationUtils {
  static getLabel(namespace: string, label: string) {
    return [namespace, label].join('.');
  }

  static getError(label: string): string {
    return this.getLabel('errors', label);
  }

  static getSuccess(label: string): string {
    return this.getLabel('success', label);
  }
}
