import * as cookie from 'js-cookie';
import {environment} from '../../environments/environment';

export const JWT_TOKEN_NAME = `trappl_jwt_${environment.name}`;
export const JWT_AUTH_SCHEMA = 'Bearer ';
export const JWT_COOKIE_DOMAIN = '.travelappeal.com';

export function tokenGetter() {
  return cookie.get(JWT_TOKEN_NAME);
}

export function tokenRemover(): Promise<void> {
  return new Promise<void>(resolve => {
    cookie.remove(JWT_TOKEN_NAME, {
      path: '',
      domain: JWT_COOKIE_DOMAIN
    });

    resolve();
  });
}

export function tokenSetter(token: string) {
  cookie.set(JWT_TOKEN_NAME, token, {
    path: '',
    domain: JWT_COOKIE_DOMAIN,
    expires: 365
  });
}
