import {environment} from '../../environments/environment';

export class HubspotUtils {

  public static loadScript(): void {
    if (environment.name !== 'prod') {
      return;
    }

    const scriptId = 'hs-script-loader';
    const scriptElement: HTMLElement = document.getElementById(scriptId);
    if (!!scriptElement) {
      return;
    }

    const hubspotScript: HTMLScriptElement = document.createElement('script');
    hubspotScript.type = 'text/javascript';
    hubspotScript.id = scriptId;
    hubspotScript.async = true;
    hubspotScript.defer = true;
    hubspotScript.src = '//js.hs-scripts.com/2245738.js';

    document.head.append(hubspotScript);
  }
}
