import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'taa-ta-logo',
  templateUrl: './ta-logo.component.html',
  styleUrls: ['./ta-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
