import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpService} from './services/http-service/http.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {createTranslateLoader} from './utils/translation-utils';
import {JwtModule} from '@auth0/angular-jwt';
import {JWT_AUTH_SCHEMA, tokenGetter} from './utils/jwt-utils';
import {AuthPageComponent} from './pages/auth-page.component';
import {LegacyService} from './services/legacy/legacy.service';
import {LibModule} from './lib/lib.module';

@NgModule({
  declarations: [
    AppComponent,
    AuthPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        authScheme: JWT_AUTH_SCHEMA
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LibModule
  ],
  providers: [
    HttpService,
    LegacyService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
