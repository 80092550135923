import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthPageComponent} from './pages/auth-page.component';

const routes: Routes = [{
  path: 'landing',
  loadChildren: './pages/landing-page/landing-page.module#LandingPageModule'
}, {
  path: '',
  component: AuthPageComponent,
  children: [{
    path: 'login',
    loadChildren: './pages/sign-in/sign-in.module#SignInModule'
  }, {
    path: 'sign-up',
    loadChildren: './pages/sign-up/sign-up.module#SignUpModule'
  }, {
    path: 'forgot-password',
    loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordModule'
  }, {
    path: 'reset-password',
    loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule'
  }, {
    path: 'logout',
    loadChildren: './pages/logout/logout.module#LogoutModule'
  }, {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login'
  }]
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
