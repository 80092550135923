import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../utils/translation-utils';

@Component({
  selector: 'taa-success-box',
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessBoxComponent implements OnInit {

  @Input() messageLabel = 'generic';

  public messageText: string;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    if (!this.messageLabel) {
      return;
    }

    const successLabel = TranslationUtils.getSuccess(this.messageLabel);
    this.translateService.get(successLabel).subscribe((translated: string) => {
      this.messageText = translated;
    });
  }

}
